import { Box, Typography, Input, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import SynapseLogo from "../../../assets/synapse-logo.svg";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { axiosAuthInstance } from "../../../utils/axiosInstance";
import MarkdownRenderer from "../../../components/markdown-renderer";
import { useSelector } from "react-redux";

const TutorChat = () => {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const { user: userInfo } = user;
  const { first_name } = userInfo;
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: `Hi ${first_name}, what do you want to learn today?`,
    },
  ]);
  const [prompt, setPrompt] = useState("");

  const handleSendMessage = () => {
    if (prompt) {
      try {
        const userMessage = { content: prompt, role: "user" };
        const updatedMessages = [...messages, userMessage];
        setMessages(updatedMessages);
        setPrompt("");
        axiosAuthInstance
          .post("/learncs/ai-chatbot/", {
            prompt,
            messages,
          })
          .then((response) => {
            const responseMessage = {
              content: response.data,
              role: "assistant",
            };
            setMessages((prevMessages) => [...prevMessages, responseMessage]);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        my: 3,
        minWidth: 450,
        maxWidth: 450,
        borderRight: "solid 2px #1F201E",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: 5,
          py: 2,
          pb: 4,
          top: 0,
          zIndex: 1000, // You can adjust the z-index as needed
        }}
      >
        <img src={SynapseLogo} width="180px" />
      </Box>
      <Box
        sx={{ px: 3, overflowY: "auto", borderRadius: 3, height: "83vh" }}
        display="flex"
        flexDirection="column"
        ref={(container) => {
          if (container) {
            container.scrollTop = container.scrollHeight;
            container.style.scrollBehavior = "smooth";
          }
        }}
      >
        <Box
          position="sticky"
          top={0}
          zIndex={1000}
          sx={{
            background:
              "linear-gradient(to bottom, rgba(17, 18, 16, 1), rgba(17, 18, 16, 0))",
            pb: 5,
          }}
        ></Box>
        <Box sx={{ px: { sm: 2, xs: 3 } }}>
          {messages.map((message, index) => (
            <Box
              display="flex"
              key={index}
              sx={{
                flexDirection: "column",
                justifyContent:
                  message.role === "user" ? "flex-end" : "flex-start",
                textAlign: message.role === "user" ? "right" : "left",
                bgcolor: message.role === "user" ? "#1F201E" : "#111210",
                borderRadius: 3,
                my: 2,
                py: message.role === "user" ? 1 : 0,
                px: message.role === "user" ? 2 : 0,
                marginLeft: message.role === "user" ? "auto" : 0, // Auto margin for user, 0 margin for Synapse
                marginRight: message.role === "user" ? 0 : "auto",
                color: "#fff",
                wordWrap: "break-word",
                width: "fit-content",
                maxWidth: "80%",
                lineHeight: 1.5,
              }}
            >
              {message.role === "user" ? (
                <Typography sx={{ fontSize: 16 }}>{message.content}</Typography>
              ) : (
                <Typography sx={{ fontSize: 16 }}>
                  <MarkdownRenderer content={message.content} />
                </Typography>
              )}
            </Box>
          ))}
        </Box>
        <Box
          position="sticky"
          bottom={0}
          zIndex={1000}
          sx={{
            background:
              "linear-gradient(to top, rgba(17, 18, 16, 1), rgba(17, 18, 16, 0))",
            pt: 8,
          }}
        ></Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            width: "inherit",
            zIndex: 1001,
            pb: 1,
          }}
        >
          <Input
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            fullWidth
            placeholder="Type your question"
            required
            disableUnderline={true}
            sx={{
              backgroundColor: "#1F201E",
              my: 2,
              py: 0.7,
              px: 3,
              borderRadius: 10,
              color: "#ECEEEC",
              fontSize: 16,
              width: "400px",
            }}
            endAdornment={
              <InputAdornment position="end">
                <Box
                  onClick={handleSendMessage}
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: prompt ? "#D4FF71" : "#111210",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(212, 255, 113, 0.8)",
                    },
                  }}
                >
                  <ArrowUpwardOutlinedIcon
                    sx={{ color: "#1F201E", fontSize: 18 }}
                  />
                </Box>
              </InputAdornment>
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TutorChat;
