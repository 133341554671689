import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import "./index.css";
import { useDispatch } from "react-redux";
import { setNewAccessRefresh } from "../../redux/auth/authSlice";

const SocialAuth = () => {
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
  let location = useLocation();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;

    if (code) {
      onGoogleLogin();
    }
  }, []);

  useEffect(() => {
    if (error) {
      alert(error);
      navigate("/login");
    }
  }, [error]);

  const googleLoginHandler = (code) => {
    return axios
      .get(`${BACKEND_API_URL}/auth/login/google/${code}`)
      .then((res) => {
        localStorage.setItem("googleFirstName", res.data.user.first_name);
        dispatch(
          setNewAccessRefresh({
            user: res.data.user,
            access: res.data.access_token,
            refresh: res.data.refresh_token,
          })
        );
        navigate("/home");
        return res.data;
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  const onGoogleLogin = async () => {
    await googleLoginHandler(location.search);
  };

  return (
    <div className="loading-spinner-container">
      <div className="spinner-overlay">
        <small className="loading-text">Just a moment...</small>
      </div>
    </div>
  );
};

export default SocialAuth;
