import { Typography, Box } from "@mui/material";
import React from "react";
import FeatureBox from "./feature-box";
import PersonalisedLesson from "../../../assets/personalised-lesson.png";
import ActiveLearning from "../../../assets/active-learning.png";

const Features = () => {
  return (
    <Box
      id="Features"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{ px: { md: 10, sm: 5, xs: 2 }, pt: 20 }}
    >
      <Typography
        sx={{
          fontSize: { md: 40, sm: 35, xs: 30 },
          fontWeight: 800,
          textAlign: "center",
          mb: 10,
        }}
      >
        Features
      </Typography>
      <Box>
        <FeatureBox
          title={"Active Learning"}
          description={"Constant feedback loop"}
          image={ActiveLearning}
        />
        <FeatureBox
          title={"Personalised"}
          description={"Ask questions"}
          image={PersonalisedLesson}
        />
      </Box>
    </Box>
  );
};

export default Features;
