import { Box, Typography } from "@mui/material";
import React from "react";
import LinkedIn from "../../../assets/linkedin.svg";
import Twitter from "../../../assets/twitter.svg";
import Instagram from "../../../assets/instagram.svg";

const Footer = () => {
  return (
    <Box
      sx={{
        pt: { md: 20, sm: 5, xs: 4 },
        pb: 10,
        mx: { md: 10, sm: 5, xs: 2 },
      }}
    >
      <Box
        display="flex"
        flexDirection={{ md: "row", xs: "column" }}
        justifyContent="space-between"
      >
        <Box sx={{ py: 2 }}>
          <Typography sx={{ color: "#687066", py: 1, fontSize: 13 }}>
            Curriculum
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            C (Programming Language)
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Advanced Programming
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Data Structures and Algorithms
          </Typography>
        </Box>
        <Box sx={{ py: 2 }}>
          <Typography sx={{ color: "#687066", py: 1, fontSize: 13 }}>
            Features
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>Personalised</Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Active Learning
          </Typography>
        </Box>
        <Box sx={{ py: 2 }}>
          <Typography sx={{ color: "#687066", py: 1, fontSize: 13 }}>
            Pricing
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>Free</Typography>
        </Box>
        <Box sx={{ py: 2 }}>
          <Typography sx={{ color: "#687066", py: 1, fontSize: 13 }}>
            Terms and Policies
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>Terms of Use</Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>Privacy Policy</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ pt: 15 }}
      >
        <Typography> SYNAPSE © 2024 </Typography>
        <Box display="flex" flexDirection="row">
          <img
            src={LinkedIn}
            alt="LinkedIn"
            width={24}
            style={{ marginRight: 10 }}
          />
          <img
            src={Twitter}
            alt="LinkedIn"
            width={24}
            style={{ marginRight: 10 }}
          />
          <img src={Instagram} alt="LinkedIn" width={24} />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
