import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ProfileIcon from "../../assets/person-icon.svg";
import { useNavigate } from "react-router";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { axiosAuthInstance } from "../../utils/axiosInstance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "2px solid #1F201E",
  backgroundColor: "#121311",
  p: 3,
  borderRadius: 3,
  width: 300,
  outline: "none",
};

const SettingsModal = ({ openSettings, handleCloseSettings }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const { user: userInfo } = user;
  const { first_name, email } = userInfo;

  return (
    <Modal
      open={openSettings}
      onClose={handleCloseSettings}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ backdropFilter: "blur(2px)" }}
    >
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="flex-end"
          onClick={handleCloseSettings}
          sx={{ cursor: "pointer" }}
        >
          <CancelRoundedIcon
            sx={{
              color: "#D4FF71",
              "&:hover": {
                color: "rgba(212, 255, 113, 0.8)",
              },
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          sx={{ height: 420 }}
        >
          <Box display="flex" alignItems="center" flexDirection="column">
            <img src={ProfileIcon} alt="ProfileIcon" />
            <Typography id="modal-modal-title" sx={{ fontSize: 22 }}>
              {first_name}
            </Typography>
            <Typography id="modal-modal-description" sx={{ fontWeight: 700 }}>
              {email}
            </Typography>
          </Box>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              sx={{ pb: 2 }}
            >
              <Typography sx={{ color: "#E3F7BA" }}> Contact us </Typography>
              <Typography sx={{ color: "#E3F7BA" }}>
                {" "}
                hello@synapsetutor.com{" "}
              </Typography>
            </Box>
            <Button
              sx={{
                backgroundColor: "#D4FF71",
                color: "#111210",
                fontWeight: 700,
                width: "100%",
                "&:hover": {
                  bgcolor: "rgba(212, 255, 113, 0.8)",
                },
                borderRadius: 100,
              }}
              onClick={() => {
                axiosAuthInstance.post("usertracking/user-press-sign-out/");
                navigate("/logout");
              }}
            >
              Log out
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SettingsModal;
