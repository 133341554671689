import { LinearProgress } from "@mui/material";
import { keyframes } from "@mui/system";
import styled from "@mui/material/styles/styled";
import { linearProgressClasses } from "@mui/material/LinearProgress";

const progressBarAnimation = keyframes`
  0% { width: 0%; }
  100% { width: 100%; }
`;

const ProgressBar = styled(LinearProgress)(({ theme, value }) => ({
  width: "80%",
  height: 8,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#434842",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: "#E3F7BA",
    width: `${value}%`,
    animation: `${progressBarAnimation} 1s linear forwards`,
  },
}));

export default ProgressBar;
