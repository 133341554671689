import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/landing-page";
import Homepage from "./pages/homepage";
import Lesson from "./pages/lesson";
import SocialAuth from "./pages/social-auth";
import LoginPage from "./pages/login";
import SignUpPage from "./pages/signup";
import PrivateRoutes from "./utils/PrivateRoute";
import Logout from "./pages/logout";

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/google" element={<SocialAuth />} />
        <Route path="/logout" element={<Logout />} />
        {/* Private Routes */}
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Homepage />} />
          <Route path="/lesson" element={<Lesson />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
