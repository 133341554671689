import React from "react";
import { Box, Typography, Button } from "@mui/material";
import BackgroundNodes from "../background-nodes";
import { useNavigate } from "react-router";

const StartLearning = () => {
  const navigate = useNavigate();
  const handleTryForFreeButton = () => {
    navigate("/signup");
  };
  return (
    <Box
      sx={{
        height: "90vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
      >
        <BackgroundNodes />
      </Box>
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ flexGrow: 1, py: 20 }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: { md: 30, sm: 20, xs: 18 },
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            Start learning computer <br />
            science fundamentals today
          </Typography>
          <Button
            onClick={handleTryForFreeButton}
            sx={{
              bgcolor: "#D4FF71",
              color: "#000",
              borderRadius: 100,
              my: 5,
              px: 3,
              "&:hover": {
                bgcolor: "rgba(212, 255, 113, 0.8)",
              },
            }}
          >
            Try for free
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default StartLearning;
