import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Inconsolata",
      textTransform: "none",
      fontSize: 17,
      fontWeight: "500",
      color: "#ECEEEC",
    },
  },
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#D4CDBE",
    },
    info: {
      main: "#B6C7FF",
    },
    neutral: {
      main: "#FFFFFF",
    },
    success: {
      main: "#F9F2EF",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        sx: {
          px: 0,
        },
        variant: "subtitle2",
        textTransform: "none",
      },
    },
    MuiStack: {
      defaultProps: {
        sx: {
          px: 2,
          py: 1,
          ml: 0,
        },
        spacing: 2,
        direction: "row",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiLink: {
      defaultProps: {
        sx: {
          color: (theme) => theme.palette.primary.main,
        },
        underline: "none",
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiStep: {
      defaultProps: {
        sx: {
          px: "50px",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "13px",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "13px",
        },
      },
    },
  },
});
const AppThemeProvider = (prop) => {
  return <ThemeProvider theme={theme}>{prop.children}</ThemeProvider>;
};

export default AppThemeProvider;
