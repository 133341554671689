import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import { Box, Typography, Input, Button } from "@mui/material";
import GoogleIcon from "../../assets/google_logo.svg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/auth/authSlice";
import BrowserSizeWarning from "../../components/browser-warning";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = useSelector((state) => state.auth);
  const { isLoggedIn } = auth;

  const handleSignUpButton = () => {
    navigate("/signup");
  };

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const scope = [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" ");
    const params = new URLSearchParams({
      response_type: "code",
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
      prompt: "select_account",
      access_type: "offline",
      scope,
    });
    const url = `${googleAuthUrl}?${params}`;
    window.location.href = url;
  }, []);

  const handleLogin = () => {
    dispatch(login({ email, password, navigate }));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home");
    }
  }, [isLoggedIn, navigate]);

  return (
    <Box sx={{ height: "100vh", backgroundColor: "#121311" }}>
      <BrowserSizeWarning />
      <Navbar />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ py: 10 }}
      >
        <Box>
          <Box sx={{ pb: 3 }}>
            <Typography sx={{ fontWeight: 800, fontSize: 20 }}>
              {" "}
              Login{" "}
            </Typography>
            <Typography sx={{ py: 1, display: "flex", flexDirection: "row" }}>
              {" "}
              Don’t have an account?{" "}
              <Typography
                onClick={handleSignUpButton}
                sx={{
                  color: "#D4FF71",
                  fontWeight: 800,
                  cursor: "pointer",
                  "&:hover": {
                    color: "rgba(212, 255, 113, 0.8)",
                  },
                }}
              >
                &nbsp;Sign up
              </Typography>{" "}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Input
              id="email"
              label="Email"
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              disableUnderline
              sx={{
                bgcolor: "#1F201E",
                width: "35vw",
                p: 1.5,
                px: 3,
                borderRadius: 10,
                my: 0.5,
                color: "#fff",
              }}
            />
            <Input
              id="password"
              label="Password"
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
              required
              disableUnderline={true}
              sx={{
                bgcolor: "#1F201E",
                width: "35vw",
                p: 1.5,
                px: 3,
                borderRadius: 10,
                my: 0.5,
                color: "#fff",
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              sx={{
                bgcolor: "#D4FF71",
                color: "#000",
                py: 1.5,
                px: 5,
                fontSize: 15,
                mt: 5,
                mb: 5,
                "&:hover": {
                  bgcolor: "rgba(212, 255, 113, 0.8)",
                },
                borderRadius: 100,
              }}
              onClick={handleLogin}
            >
              {" "}
              Login with email{" "}
            </Button>
            <Box display="flex" alignItems="center" width="100%">
              <Box flex="1" borderBottom="1px solid #1F201E" />
              <Typography mx={1}>Or</Typography>
              <Box flex="1" borderBottom="1px solid #1F201E" />
            </Box>
            <Button
              sx={{
                bgcolor: "#fff",
                color: "#000",
                // width: "170px",
                py: 1.5,
                mt: 5,
                px: 3,
                fontSize: 15,
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.8)",
                },
                borderRadius: 100,
              }}
              onClick={openGoogleLoginPage}
            >
              <img
                src={GoogleIcon}
                alt="Google Icon"
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                }}
              />
              Login with Google{" "}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
