import React, { useState, useRef, useEffect } from "react";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { Box, Typography, Input, Button, InputAdornment } from "@mui/material";
import { axiosAuthInstance } from "../../utils/axiosInstance";
import { useSelector } from "react-redux";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import MarkdownRenderer from "../markdown-renderer";

const AskTutor = ({
  messages,
  setMessages,
  messagesCount,
  setMessagesCount,
  pageId,
  setPageHistory,
  pageHistory,
}) => {
  const [showTutor, setShowTutor] = useState(false);
  const tutorState = useSelector((state) => state.tutor);
  const { pages, currentPageIndex } = tutorState;
  const [prompt, setPrompt] = useState("");
  const [responseGenerated, setResponseGenerated] = useState(true);
  const [suggestions, setSuggestions] = useState([
    "How is this relevant to me?",
    "I don't understand anything.",
  ]);

  const handleClick = () => {
    setShowTutor(!showTutor);
  };

  const handleSendMessage = async () => {
    // Prevent empty prompt
    if (!responseGenerated || !prompt.trim()) {
      return;
    }
    setResponseGenerated(false);

    const userMessage = { content: prompt, role: "user" };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setPrompt("");
    setMessagesCount(messagesCount + 1);

    try {
      const response = await axiosAuthInstance.post(
        `/learncs/ask-followup-page-with-history/${pages[currentPageIndex]?.id}/`,
        { prompt, messages: updatedMessages }
      );
      const responseMessage = { content: response.data, role: "assistant" };
      setMessages((prevMessages) => [...prevMessages, responseMessage]);
    } catch (error) {
      console.log(error);
    }
    setResponseGenerated(true);
  };

  const handleSendMessageWithPageId = async () => {
    // Prevent empty prompt
    if (!responseGenerated || !prompt.trim()) {
      return;
    }
    setResponseGenerated(false);

    const userMessage = { content: prompt, role: "user" };
    const updatedMessages = [...messages, userMessage];
    pageHistory.map((page) => {
      if (page.id === pageId) {
        page.messages = updatedMessages;
        page.messagesCount += 1;
      }
    });
    setPrompt("");

    try {
      const response = await axiosAuthInstance.post(
        `/learncs/ask-followup-page-with-history/${pageId}/`,
        { prompt, messages: updatedMessages }
      );
      const responseMessage = { content: response.data, role: "assistant" };
      const updatedPageHistory = pageHistory.map((page) => {
        if (page.id === pageId) {
          page.messages.push(responseMessage);
        }
        return page;
      });
      setPageHistory(updatedPageHistory);
    } catch (error) {
      console.log(error);
    }
    setResponseGenerated(true);
  };

  const handleClickSuggestion = (suggestion) => {
    const userMessage = { content: suggestion, role: "user" };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setMessagesCount(messagesCount + 1);

    try {
      axiosAuthInstance
        .post(
          `/learncs/ask-followup-page-with-history/${pages[currentPageIndex]?.id}/`,
          { prompt: suggestion, messages: updatedMessages }
        )
        .then((response) => {
          const responseMessage = { content: response.data, role: "assistant" };
          setMessages((prevMessages) => [...prevMessages, responseMessage]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSuggestionWithPageId = (suggestion) => {
    const userMessage = { content: suggestion, role: "user" };
    const updatedMessages = [...messages, userMessage];
    pageHistory.map((page) => {
      if (page.id === pageId) {
        page.messages = updatedMessages;
        page.messagesCount += 1;
      }
    });

    try {
      axiosAuthInstance
        .post(`/learncs/ask-followup-page-with-history/${pageId}/`, {
          prompt: suggestion,
          messages: updatedMessages,
        })
        .then((response) => {
          const responseMessage = { content: response.data, role: "assistant" };
          const updatedPageHistory = pageHistory.map((page) => {
            if (page.id === pageId) {
              page.messages.push(responseMessage);
            }
            return page;
          });
          setPageHistory(updatedPageHistory);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        onClick={handleClick}
        sx={{
          my: 3,
        }}
      >
        <Button
          sx={{
            borderRadius: 100,
            "&:hover": {
              color: "#D4FF71",
              background: "#111210",
              "& > *": {
                color: "#D4FF71",
              },
            },
          }}
        >
          <QuestionAnswerOutlinedIcon
            sx={{
              color: showTutor ? "#D4FF71" : "#434842",
              fontSize: 17,
              mx: 1,
            }}
          />
          <Typography
            sx={{ color: showTutor ? "#D4FF71" : "#434842", fontSize: 16 }}
          >
            {messagesCount}
          </Typography>
          {showTutor ? (
            <ExpandLessOutlinedIcon
              sx={{
                color: showTutor ? "#D4FF71" : "#434842",
                fontSize: 18,
                ml: 0.5,
              }}
            />
          ) : (
            <ExpandMoreOutlinedIcon
              sx={{
                color: showTutor ? "#D4FF71" : "#434842",
                fontSize: 18,
                ml: 0.5,
              }}
            />
          )}
        </Button>
      </Box>
      {showTutor && (
        <Box>
          <Box
            sx={{
              borderLeft: "solid 2px #1F201E",
              borderRight: "solid 2px #1F201E",
              height: 300,
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
            ref={(container) => {
              if (container) {
                container.scrollTop = container.scrollHeight;
                container.style.scrollBehavior = "smooth";
              }
            }}
          >
            <Box
              position="sticky"
              top={0}
              zIndex={1000}
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(17, 18, 16, 1), rgba(17, 18, 16, 0))",
                pb: 5,
              }}
            ></Box>
            {/* FollowUp Suggestions */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                px: { sm: 2, xs: 3 },
              }}
            >
              {suggestions.map((suggestion, index) => (
                <Button
                  sx={{
                    border: "solid 1px #D4FF71",
                    width: "fit-content",
                    maxWidth: "40%",
                    borderRadius: 100,
                    px: 2,
                    py: 1,
                    color: "#D4FF71",
                    fontSize: 14,
                    mt: 1.5,
                    textAlign: "right",
                    // Hover color
                    "&:hover": {
                      backgroundColor: "#D4FF71",
                      color: "#111210",
                    },
                  }}
                  onClick={() =>
                    pageId
                      ? handleClickSuggestionWithPageId(suggestion)
                      : handleClickSuggestion(suggestion)
                  }
                >
                  {suggestion}
                </Button>
              ))}
              {pages[currentPageIndex]?.followup_suggestion && (
                <Button
                  sx={{
                    border: "solid 1px #D4FF71",
                    width: "fit-content",
                    maxWidth: "40%",
                    borderRadius: 8,
                    px: 2,
                    py: 1,
                    color: "#D4FF71",
                    fontSize: 14,
                    mt: 1.5,
                    textAlign: "right",
                    // Hover color
                    "&:hover": {
                      backgroundColor: "#D4FF71",
                      color: "#111210",
                    },
                  }}
                  onClick={() =>
                    pageId
                      ? handleClickSuggestionWithPageId(
                          pages[currentPageIndex]?.followup_suggestion
                        )
                      : handleClickSuggestion(
                          pages[currentPageIndex]?.followup_suggestion
                        )
                  }
                >
                  {pages[currentPageIndex]?.followup_suggestion}
                </Button>
              )}
            </Box>
            <Box sx={{ px: { sm: 2, xs: 3 } }}>
              {messages.map((message, index) => (
                <Box
                  display="flex"
                  key={index}
                  sx={{
                    flexDirection: "column",
                    justifyContent:
                      message.role === "user" ? "flex-end" : "flex-start",
                    textAlign: message.role === "user" ? "right" : "left",
                    bgcolor: message.role === "user" ? "#1F201E" : "#111210",
                    borderRadius: 3,
                    my: 2,
                    py: message.role === "user" ? 1 : 0,
                    px: message.role === "user" ? 2 : 0,
                    marginLeft: message.role === "user" ? "auto" : 0, // Auto margin for user, 0 margin for Synapse
                    marginRight: message.role === "user" ? 0 : "auto",
                    color: "#fff",
                    wordWrap: "break-word",
                    width: "fit-content",
                    maxWidth: "80%",
                    lineHeight: 1.5,
                  }}
                >
                  {message.role === "user" ? (
                    <Typography sx={{ fontSize: 16 }}>
                      {message.content}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: 16 }}>
                      <MarkdownRenderer content={message.content} />
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
            <Box
              position="sticky"
              bottom={0}
              zIndex={1000}
              sx={{
                background:
                  "linear-gradient(to top, rgba(17, 18, 16, 1), rgba(17, 18, 16, 0))",
                pt: 5,
              }}
            ></Box>
          </Box>
          <Input
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            fullWidth
            placeholder="Type your question"
            required
            disableUnderline={true}
            disabled={!responseGenerated}
            sx={{
              backgroundColor: "#1F201E",
              my: 2,
              py: 0.7,
              px: 3,
              borderRadius: 10,
              color: "#ECEEEC",
              fontSize: 16,
            }}
            endAdornment={
              <InputAdornment position="end">
                <Box
                  onClick={
                    responseGenerated
                      ? pageId
                        ? handleSendMessageWithPageId
                        : handleSendMessage
                      : null
                  }
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: prompt ? "#D4FF71" : "#111210",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowUpwardOutlinedIcon
                    sx={{ color: "#1F201E", fontSize: 18 }}
                  />
                </Box>
              </InputAdornment>
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                pageId ? handleSendMessageWithPageId() : handleSendMessage();
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AskTutor;
