import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const style = {
  position: "absolute",
  top: "50%",
  left: "65%",
  transform: "translate(-50%, -50%)",
  border: "2px solid #1F201E",
  backgroundColor: "#121311",
  p: 3,
  borderRadius: 3,
  width: 280,
  outline: "none",
};

const StartLessonModal = ({
  openSubtopic,
  handleCloseSubtopic,
  handleStartLesson,
  modalContentSubtopic,
}) => {
  return (
    <Modal
      open={openSubtopic}
      onClose={handleCloseSubtopic}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="flex-end"
          onClick={handleCloseSubtopic}
          sx={{ cursor: "pointer" }}
        >
          <CancelRoundedIcon
            sx={{
              color: "#D4FF71",
              "&:hover": {
                color: "rgba(212, 255, 113, 0.8)",
              },
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          sx={{ height: 420 }}
        >
          <Box>
            <Typography id="modal-modal-title" sx={{ fontSize: 22 }}>
              {modalContentSubtopic.title}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{
                mt: 2,
                color:
                  modalContentSubtopic.status === "In Progress"
                    ? "#E3F7BA"
                    : modalContentSubtopic.status === "Completed"
                    ? "#449C3D"
                    : "#30322F",
                fontWeight: 700,
              }}
            >
              {modalContentSubtopic.status}
            </Typography>
          </Box>
          <Box>
            {modalContentSubtopic.status === "In Progress" ? (
              <Button
                onClick={handleStartLesson}
                sx={{
                  backgroundColor: "#D4FF71",
                  color: "#111210",
                  fontWeight: 700,
                  width: "100%",
                  "&:hover": {
                    bgcolor: "rgba(212, 255, 113, 0.8)",
                  },
                  borderRadius: 100,
                }}
              >
                {" "}
                Start Lesson{" "}
              </Button>
            ) : (
              <Button
                onClick={handleStartLesson}
                sx={{
                  backgroundColor: "#1F201E",
                  color: "#474C46",
                  fontWeight: 700,
                  width: "100%",
                  borderRadius: 100,
                }}
                disabled
              >
                {" "}
                Start Lesson{" "}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default StartLessonModal;
