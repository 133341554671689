import { Box } from "@mui/material";
import React from "react";
import TutorChat from "./tutor";
import SynapseNodes from "./nodes";
import BrowserSizeWarning from "../../components/browser-warning";

const Homepage = () => {
  return (
    <Box
      display="flex"
      height="100vh"
      maxWidth="1600px"
      sx={{
        marginX: "auto", // This will center the content horizontally
        backgroundColor: "#111210",
      }}
    >
      <BrowserSizeWarning />
      <TutorChat />
      <SynapseNodes />
    </Box>
  );
};

export default Homepage;
