import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import SynapseLogo from "../../assets/synapse-logo.svg";
import { useNavigate, useLocation } from "react-router";
import { Link as ScrollLink } from "react-scroll";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState();
  const menuAnchorRef = useRef();

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleLoginButton = () => {
    navigate("/login");
  };

  const handleLogoClicked = () => {
    navigate("/");
  };
  return (
    <Box
      display="flex"
      alignContent="flex"
      justifyContent="space-between"
      position="sticky"
      top={0}
      zIndex={1000}
      sx={{
        backgroundColor: "#1F201E",
        color: "#fff",
        py: location.pathname === "/" ? 2 : 3.2,
        px: 4,
        borderBottom: "solid 1px #30322F",
      }}
    >
      <img
        src={SynapseLogo}
        width="180px"
        onClick={handleLogoClicked}
        style={{ cursor: "pointer" }}
        alt="SynapseLogo"
      />
      {location.pathname !== "/login" && location.pathname !== "/signup" && (
        <>
          <Box
            display={{ xs: "none", md: "flex" }}
            flexDirection="row"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ px: 3 }}
            >
              <ScrollLink to="Curriculum" smooth={true} duration={500}>
                <Typography
                  sx={{
                    px: 2,
                    color: "#687066",
                    cursor: "pointer",
                    "&:hover": { color: "#D4CDBE" },
                  }}
                >
                  {" "}
                  Curriculum{" "}
                </Typography>
              </ScrollLink>
              <ScrollLink to="Features" smooth={true} duration={500}>
                <Typography
                  sx={{
                    px: 2,
                    color: "#687066",
                    cursor: "pointer",
                    "&:hover": { color: "#D4CDBE" },
                  }}
                >
                  {" "}
                  Features{" "}
                </Typography>
              </ScrollLink>
              <ScrollLink to="Pricing" smooth={true} duration={500}>
                <Typography
                  sx={{
                    px: 2,
                    color: "#687066",
                    cursor: "pointer",
                    "&:hover": { color: "#D4CDBE" },
                  }}
                >
                  {" "}
                  Pricing{" "}
                </Typography>
              </ScrollLink>
            </Box>
            <Button
              onClick={handleLoginButton}
              sx={{
                bgcolor: "#D4FF71",
                color: "#000",
                borderRadius: 100,
                fontSize: 15,
                px: 4,
                "&:hover": {
                  bgcolor: "rgba(212, 255, 113, 0.8)",
                },
              }}
            >
              Login
            </Button>
          </Box>
          {/* Responsive Button */}
          <Box display={{ xs: "flex", md: "none" }}>
            <IconButton
              ref={menuAnchorRef}
              edge="start"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuRoundedIcon sx={{ color: "#687066" }} />
            </IconButton>
            <Menu
              anchorEl={menuAnchorRef.current}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={isMenuOpen}
              onClose={handleMenuClose}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#1F201E",
                  border: "solid 1px #687066",
                  borderRadius: 3,
                },
              }}
            >
              <MenuItem>
                <ScrollLink to="Curriculum" smooth={true} duration={500}>
                  <Typography>Curriculum</Typography>
                </ScrollLink>
              </MenuItem>
              <MenuItem>
                <ScrollLink to="Features" smooth={true} duration={500}>
                  <Typography>Features</Typography>
                </ScrollLink>
              </MenuItem>
              <MenuItem>
                <ScrollLink to="Pricing" smooth={true} duration={500}>
                  <Typography>Pricing</Typography>
                </ScrollLink>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={handleLoginButton}
                  sx={{
                    bgcolor: "#D4FF71",
                    color: "#000",
                    fontSize: 15,
                    borderRadius: 100,
                    px: 4,
                    "&:hover": {
                      bgcolor: "rgba(212, 255, 113, 0.8)",
                    },
                  }}
                >
                  Login
                </Button>
              </MenuItem>
            </Menu>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Navbar;
