import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LessonTypeOne from "./type-1";
import LessonTypeTwo from "./type-2";
import LessonTypeThree from "./type-3";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  getPagesBySubtopicName,
  incrementPageIndex,
} from "../../redux/tutor/tutorSlice";
import { useNavigate, useLocation } from "react-router";
import ProgressBar from "../../components/progress-bar";
import LoadingAnimation from "../../components/loading-animation";
import { axiosAuthInstance } from "../../utils/axiosInstance";
import LeaveLessonModal from "../../components/leave-lesson";

const Lesson = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { subtopicTitle } = location.state || {};
  const tutorState = useSelector((state) => state.tutor);
  const { pages, currentPageIndex, currentSubtopic } = tutorState;
  const dispatch = useDispatch();
  const [userAnswer, setUserAnswer] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const [response, setResponse] = useState("");
  const [userCodeOutput, setUserCodeOutput] = useState("");
  const [currentPage, setCurrentPage] = useState(pages[currentPageIndex]);
  const [userCode, setUserCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageHistory, setPageHistory] = useState([]);
  const [openLeaveLesson, setOpenLeaveLesson] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesCount, setMessagesCount] = useState(0);

  const handleOpenLeaveLesson = () => {
    setOpenLeaveLesson(true);
    axiosAuthInstance.post("usertracking/user-press-leave-lesson-icon/");
  };

  const handleCloseLeaveLesson = () => {
    setOpenLeaveLesson(false);
    axiosAuthInstance.post("usertracking/user-press-cancel-leave-lesson/");
  };

  useEffect(() => {
    setLoading(true);
    if (pages.length === 0) {
      dispatch(getPagesBySubtopicName(currentSubtopic));
    }
    setLoading(false);
  }, [currentSubtopic, dispatch, pages.length]);

  useEffect(() => {
    // console.log("Page History:", pageHistory);
    // console.log("Current Code Editor Content:", pages[currentPageIndex]?.code);
    setCurrentPage(pages[currentPageIndex]);
    setUserCode(pages[currentPageIndex]?.code);
  }, [currentPageIndex, pages]);

  const checkMoveToNextPage = () => {
    if (currentPage?.question && (userAnswer || showAnswer)) {
      return true;
    } else if (currentPage?.code && userCode && userCodeOutput.length > 0) {
      return true;
    } else if (!currentPage?.question && !currentPage?.code) {
      return true;
    }
    return false;
  };

  const moveToNextPage = async () => {
    if (currentPageIndex !== pages.length - 1) {
      setPageHistory([
        ...pageHistory,
        {
          pageId: currentPage?.id,
          ...currentPage,
          userAnswer,
          userCode,
          userCodeOutput,
          showAnswer,
          response,
          messages,
          setMessages,
          messagesCount,
          setMessagesCount,
        },
      ]);
      setMessages([]);
      setMessagesCount(0);
      if (currentPage?.question && (userAnswer || showAnswer)) {
        dispatch(incrementPageIndex(currentPageIndex));
        setUserAnswer("");
        setShowAnswer(false);
      } else if (currentPage?.code && userCode && userCodeOutput.length > 0) {
        dispatch(incrementPageIndex(currentPageIndex));
        setUserCode("");
        setUserCodeOutput("");
      } else if (!currentPage?.question && !currentPage?.code) {
        dispatch(incrementPageIndex(currentPageIndex));
      }
    } else {
      // Complete lesson
      await axiosAuthInstance.post("/learncs/complete-lesson/", {
        subtopic_name: currentSubtopic,
      });
      navigate("/home");
    }
  };

  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Box maxWidth="1600px" sx={{ marginX: "auto" }}>
          <Box
            maxWidth="1600px"
            sx={{
              position: "fixed",
              top: 0.5,
              bottom: 12,
              overflowY: "auto",
              width: "100%",
              zIndex: -1,
              scrollbarWidth: "none",
              backgroundColor: "#111210",
            }}
            ref={(container) => {
              if (container) {
                container.scrollTop = container.scrollHeight;
                container.style.scrollBehavior = "smooth";
              }
            }}
          >
            <Box
              position="sticky"
              top={0}
              zIndex={1000}
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(17, 18, 16, 1), rgba(17, 18, 16, 0))",
                height: 40,
              }}
            ></Box>
            <Box
              sx={{
                mx: {
                  md: "20%",
                  sm: "12%",
                  xs: "7%",
                },
                mb: 1,
              }}
            >
              <Typography sx={{ fontWeight: 800, fontSize: 25 }}>
                {subtopicTitle}
              </Typography>
              {pageHistory.map((page, index) => {
                return (
                  <Box key={index}>
                    {page.question ? (
                      <LessonTypeThree
                        page={page.pageId}
                        question={page.question}
                        answer={page.answer}
                        showAnswer={page.showAnswer}
                        currentPage={page}
                        answerChoices={page.answer_choices}
                        isMultipleChoice={page.is_multiple_choice}
                        response={page.response}
                        userAnswer={page.userAnswer}
                        messages={page.messages}
                        messagesCount={page.messagesCount}
                        pageHistory={pageHistory}
                        setPageHistory={setPageHistory}
                      />
                    ) : page.code ? (
                      <LessonTypeTwo
                        page={page.pageId}
                        content={page.content}
                        userCode={page.userCode}
                        userCodeOutput={page.userCodeOutput}
                        image={page.image}
                        currentFileName={page.file_name}
                        messages={page.messages}
                        messagesCount={page.messagesCount}
                        pageHistory={pageHistory}
                        setPageHistory={setPageHistory}
                      />
                    ) : (
                      <LessonTypeOne
                        pageId={page.pageId}
                        content={page.content}
                        image={page.image}
                        messages={page.messages}
                        messagesCount={page.messagesCount}
                        pageHistory={pageHistory}
                        setPageHistory={setPageHistory}
                      />
                    )}
                  </Box>
                );
              })}
              {pages[currentPageIndex] && (
                <Box>
                  {pages[currentPageIndex]?.question ? (
                    <LessonTypeThree
                      question={pages[currentPageIndex]?.question}
                      answer={pages[currentPageIndex]?.answer}
                      setShowAnswer={setShowAnswer}
                      showAnswer={showAnswer}
                      currentPage={currentPage}
                      answerChoices={pages[currentPageIndex]?.answer_choices}
                      isMultipleChoice={
                        pages[currentPageIndex]?.is_multiple_choice
                      }
                      userAnswer={userAnswer}
                      setUserAnswer={setUserAnswer}
                      response={response}
                      setResponse={setResponse}
                      messages={messages}
                      setMessages={setMessages}
                      messagesCount={messagesCount}
                      setMessagesCount={setMessagesCount}
                    />
                  ) : pages[currentPageIndex]?.code ? (
                    <LessonTypeTwo
                      content={pages[currentPageIndex]?.content}
                      originalCode={pages[currentPageIndex]?.code}
                      userCode={userCode}
                      setUserCode={setUserCode}
                      userCodeOutput={userCodeOutput}
                      setUserCodeOutput={setUserCodeOutput}
                      image={pages[currentPageIndex]?.image}
                      currentFileName={pages[currentPageIndex]?.file_name}
                      messages={messages}
                      setMessages={setMessages}
                      messagesCount={messagesCount}
                      setMessagesCount={setMessagesCount}
                    />
                  ) : (
                    <LessonTypeOne
                      content={pages[currentPageIndex]?.content}
                      image={pages[currentPageIndex]?.image}
                      messages={messages}
                      setMessages={setMessages}
                      messagesCount={messagesCount}
                      setMessagesCount={setMessagesCount}
                    />
                  )}
                </Box>
              )}
            </Box>
            <Box
              maxWidth="1600px"
              display="flex"
              sx={{
                width: "100%",
                position: "fixed",
                bottom: 0,
                zIndex: 1001,
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  bgcolor: "#1F201E",
                  py: 2,
                  width: "100%",
                  borderTop: "solid 1.5px #30322F",
                }}
              >
                <LogoutRoundedIcon
                  onClick={handleOpenLeaveLesson}
                  sx={{
                    color: "#687066",
                    fontSize: 20,
                    mx: 1.5,
                    rotate: "180deg",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#C33A3B",
                    },
                  }}
                />
                <ProgressBar
                  variant="determinate"
                  value={10 + (currentPageIndex / (pages.length - 1)) * 90}
                />
                <Box
                  onClick={moveToNextPage}
                  disabled={!checkMoveToNextPage()}
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: checkMoveToNextPage()
                      ? "#D4FF71"
                      : "#434842",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    mx: 1.5,
                    "&:hover": {
                      bgcolor: "rgba(212, 255, 113, 0.8)",
                    },
                  }}
                >
                  <ArrowForwardOutlinedIcon
                    sx={{
                      color: "#111210",
                      fontSize: 18,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <LeaveLessonModal
        openLeaveLesson={openLeaveLesson}
        handleCloseLeaveLesson={handleCloseLeaveLesson}
      />
    </>
  );
};

export default Lesson;
