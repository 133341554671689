import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";

const AnswerExplanation = ({ answer }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        color: "#E3F7BA",
        border: "solid 1px #E3F7BA",
        borderRadius: 3,
        p: 2,
        my: 2,
        whiteSpace: "pre-wrap",
      }}
    >
      <InfoOutlinedIcon sx={{ mr: 2, fontSize: 25 }} />
      <Typography sx={{ color: "#E3F7BA", fontSize: 16 }}>{answer}</Typography>
    </Box>
  );
};

export default AnswerExplanation;
