import { Typography, Box } from "@mui/material";
import React from "react";

const FeatureBox = ({ title, description, image }) => {
  return (
    <Box
      display="flex"
      flexDirection={{ md: "row", sm: "row", xs: "column" }}
      justifyContent="space-between"
      alignItems={{ md: "center", sm: "center", xs: "none" }}
      sx={{
        border: "solid 1px #434842",
        backgroundColor: "#1F201E",
        borderRadius: 3,
        mb: 7,
      }}
    >
      <Box sx={{ px: { md: 15, sm: 5, xs: 2 } }}>
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: { md: 30, sm: 22, xs: 20 },
            py: 2.5,
          }}
        >
          {" "}
          {title}{" "}
        </Typography>
        <Typography sx={{ pb: { md: 0, sm: 0, xs: 2.5 } }}>
          {description}
        </Typography>
      </Box>
      <Box>
        <img
          src={image}
          alt="Features"
          style={{ borderRadius: 10 }}
          width={"100%"}
        />
      </Box>
    </Box>
  );
};

export default FeatureBox;
